import { createRouter, createWebHistory } from 'vue-router';

import storeGuard from './guards/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Roster' },
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/roster',
    name: 'Roster',
    component: () => import('@/views/Roster.vue'),
    meta: {
      title: 'Roster',
    },
  },
  {
    path: '/character/:name',
    sensitive: true,
    name: 'Character',
    redirect: { name: 'Character/Detail' },
    component: () => import('@/views/Character.vue'),
    props: true,
    meta: {
      title: 'Character',
    },
    children: [
      {
        path: 'summary',
        sensitive: true,
        name: 'Character/Detail',
        component: () => import('@/views/CharacterDetails.vue'),
        props: true,
        meta: {
          title: 'Character - Details',
          showBackButton: true,
        },
      },
    ],
  },
  {
    path: '/raiders',
    name: 'Raiders',
    component: () => import('@/views/Raiders.vue'),
    meta: {
      title: 'Raiders',
    },
  },
  {
    path: '/loot',
    name: 'Loot',
    component: () => import('@/views/Loot.vue'),
    meta: {
      title: 'Loot',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_URL),

  linkActiveClass: '',
  linkExactActiveClass: 'AppNavigation__Item__Link--Active',

  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.name === 'Report/Detail') {
  //   document.getElementById('main').scrollIntoView({ top: 0, behavior: 'smooth' });
  // } else {
  //   document.getElementById('header').scrollIntoView({ top: 0, behavior: 'smooth' });
  // }

  // const appStore = useAppStore();

  // to.meta.showBackButton ? (appStore.showBackButton = true) : (appStore.showBackButton = false);

  next();
});

router.beforeEach(storeGuard);

export default router;
