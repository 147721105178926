export const mainNavigation = [
  {
    url: '/roster',
    name: 'Roster',
    icon: PhUsersThree,
  },
  {
    url: '/raiders',
    name: 'Raiders',
    icon: PhSword,
  },
  {
    url: '/loot',
    name: 'Loot',
    icon: PhCrown,
  },
];
