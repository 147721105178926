<template>
  <div class="VideoBackground">
    <video
      :key="currentVideo"
      class="VideoBackground__Video"
      autoplay
      muted
      loop
      playsinline
      :poster="posterSource"
    >
      <source :src="videoSource" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script setup>
const props = defineProps({
  videos: {
    type: Array,
    required: true,
    validator: (value) => value.every((video) => typeof video === 'string'),
  },
  posters: {
    type: Array,
    required: true,
    validator: (value) => value.every((poster) => typeof poster === 'string'),
  },
  selected: {
    type: String,
    default: null,
  },
  selectedPoster: {
    type: String,
    default: null,
  },
  random: {
    type: Boolean,
    default: false,
  },
});

const currentVideoIndex = computed(() => {
  if (props.random || !props.selected) {
    return Math.floor(Math.random() * props.videos.length);
  }
  return props.videos.indexOf(props.selected);
});

// Computed video and poster paths
const currentVideo = computed(() => props.videos[currentVideoIndex.value] || props.videos[0]);
const videoSource = computed(
  () => new URL(`/src/assets/images/background/${currentVideo.value}.mp4`, import.meta.url).href
);

const currentPoster = computed(() => {
  if (props.selectedPoster) return props.selectedPoster;
  return props.posters[currentVideoIndex.value] || props.posters[0];
});
const posterSource = computed(
  () => new URL(`/src/assets/images/background/${currentPoster.value}`, import.meta.url).href
);
</script>

<style scoped lang="scss">
.VideoBackground {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &__Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
